import { Injectable } from '@angular/core';
import { AlertController, Platform } from '@ionic/angular';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Subscription, iif } from 'rxjs';
import { ConfigService } from '../config/ungs_activa_mobile_config';
import { HerramientasService } from '../herramientas/herramientas.service';

import { Device } from '@ionic-native/device/ngx';
import { FirebaseMessaging } from '@ionic-native/firebase-messaging/ngx';

import * as firebase_app from 'firebase/app';
import 'firebase/messaging';

@Injectable({
  providedIn: 'root',
})
export class NotificacionesService {
  config = ConfigService;
  estaSoportado = firebase_app.messaging.isSupported();
  messaging: firebase_app.messaging.Messaging;
  nativeSubscriptionF: Subscription;
  nativeSubscriptionB: Subscription;
  nativeNotification: any = new BehaviorSubject(null);

  desktopSubscription: firebase_app.Unsubscribe;
  desktopNotification: any = new BehaviorSubject(null);

  constructor(
    private http: HttpClient,
    private device: Device,
    private platform: Platform,
    private firebaseMessaging: FirebaseMessaging,
    private alertCtrl: AlertController,
    public herramientas: HerramientasService
  ) {
    if(this.estaSoportado){
      this.messaging = firebase_app.messaging();
    }
  }

  desktop_requestPermission(): Promise<any> {
    if (this.estaSoportado) {
      return this.messaging.requestPermission();
    }
  }

  desktop_getToken() {
    if (this.estaSoportado) {
      this.messaging
        .getToken()
        .then(token => this.updateToken(token))
        .catch(error => {
          console.warn('No se puede habilitar las Notificaciones');
        });
    } else {
      let ungsxtUser: any;
      if (this.herramientas.storage().getItem('activa') !== null) {
        ungsxtUser = JSON.parse(this.herramientas.storage().getItem('activa'));
      }

      if (!ungsxtUser.mensaje_notificaciones) {
        this.alertCtrl
          .create({
            header: 'Notificaciones',
            message:
              'Por el momento las notificaciones están deshabilitadas en este navegador cuando se usa la navegación privada o se deshabilita el historial<br/><br/>Si desea recibir notificaciones en su navegador, por favor habilite el historial o no use una navegación privada.',
            buttons: [
              {
                text: 'Entiendo',
                role: 'aceptar',
                handler: () => {
                  ungsxtUser.mensaje_notificaciones = true;
                  this.herramientas.storage().setItem('activa', JSON.stringify(ungsxtUser));
                },
              },
            ],
          })
          .then(alert => alert.present())
          .catch(alertNoCreada => console.warn(alertNoCreada));
      }
    }
  }

  desktop_onTokenRefresh() {
    if (this.estaSoportado) {
      this.messaging.onTokenRefresh(() => {
        this.messaging
          .getToken()
          .then(token => {
            console.log('onTokenRefresh', token);
            this.updateToken(token);
          })
          .catch(error => console.log('No se puede actualizar el token de Notificaciones', JSON.stringify(error)));
      });
    }
  }

  desktop_onNotification() {
    if (this.estaSoportado) {
      this.desktopSubscription = this.messaging.onMessage(payload => {
        if (payload) {
          this.desktopNotification.next(payload);
        } else {
          this.desktopNotification.next(null);
        }
      });
    }
  }

  desktop_unsubscribeOnNotification() {
    if (this.estaSoportado) {
      if (this.desktopSubscription !== undefined) {
        if (typeof this.desktopSubscription === 'function') {
          this.desktopSubscription();
        }
      }
    }
  }

  native_getToken() {
    this.firebaseMessaging.getToken().then(token => this.updateToken(token));
  }

  native_onNotification() {
    this.nativeSubscriptionF = this.firebaseMessaging.onMessage().subscribe(data => {
      if (data) {
        this.nativeNotification.next(data);
      } else {
        this.nativeNotification.next(null);
      }
    });

    this.nativeSubscriptionB = this.firebaseMessaging.onBackgroundMessage().subscribe(data => {
      if (data) {
        this.nativeNotification.next(data);
      } else {
        this.nativeNotification.next(null);
      }
    });
  }

  native_onTokenRefresh() {
    this.firebaseMessaging.onTokenRefresh().subscribe(() => {
      this.firebaseMessaging.getToken().then(token => this.updateToken(token));
    });
  }

  native_unsubscribeOnNotification() {
    if (this.nativeSubscriptionF !== undefined) {
      this.nativeSubscriptionF.unsubscribe();
    }

    if (this.nativeSubscriptionB !== undefined) {
      this.nativeSubscriptionB.unsubscribe();
    }
  }

  updateToken(newToken: string) {
    const device = this.device,
      platform = this.platform;

    const d = {
      uuid: device.uuid,
      serial: device.serial,
      platform: device.platform,
      model: device.model,
      manufacturer: device.manufacturer,
    };

    this.http
      .post(
        this.config.API_URL + '/firebase/mensajes/registro',
        {
          token: newToken,
          device: d,
          platforms: platform.platforms(),
        },
        { responseType: 'text' }
      )
      .toPromise()
      .then((response: any) => {})
      .catch((error: any) => console.log('updateToken', error));
  }
}
