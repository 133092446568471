import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from '../config/ungs_activa_mobile_config';
import { HerramientasService } from '../herramientas/herramientas.service';

@Injectable({
  providedIn: 'root'
})
export class SolicitudIngresoCampusService {

  config = ConfigService;

  constructor(private http: HttpClient, public herramientas: HerramientasService) {}

  traerConfiguracionDependencias(filtro?: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .get(this.config.API_URL + '/ungs_activa/solicitud_ingreso_campus/configuracion_dependencias', { params: filtro })
        .toPromise()
        .then((response: any) => resolve(response))
        .catch((error: any) => reject(error));
    });
  }

  traerArchivos(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .get(this.config.API_URL + '/ungs_activa/solicitud_ingreso_campus/configuracion_archivos')
        .toPromise()
        .then((response: any) => resolve(response))
        .catch((error: any) => reject(error));
    });
  }

  traerSolicitudes(filtro?: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .get(this.config.API_URL + '/ungs_activa/solicitud_ingreso_campus/solicitudes', { params: filtro })
        .toPromise()
        .then((response: any) => resolve(response))
        .catch((error: any) => reject(error));
    });
  }

  guardarSolicitud(solicitud: Object) {
    return new Promise((resolve, reject) => {
      this.http
        .post(this.config.API_URL + '/ungs_activa/solicitud_ingreso_campus/solicitudes', solicitud)
        .toPromise()
        .then((response: any) => resolve(response))
        .catch((error: any) => reject(error));
    });
  }

  cancelarSolicitud(solicitud: Object) {
    return new Promise((resolve, reject) => {
      this.http
        .delete(this.config.API_URL + '/ungs_activa/solicitud_ingreso_campus/cancelar_solicitud', {params: {id_solicitud: solicitud['id']}})
        .toPromise()
        .then((response: any) => resolve(response))
        .catch((error: any) => reject(error));
    });
  }
}
