import { NgModule, LOCALE_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { registerLocaleData } from '@angular/common';
import localeEsAr from '@angular/common/locales/es-AR';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { AppVersion } from '@ionic-native/app-version/ngx';
import { BarcodeScanner } from '@ionic-native/barcode-scanner/ngx';
import { Camera } from '@ionic-native/camera/ngx';
import { Device } from '@ionic-native/device/ngx';
import { File } from '@ionic-native/file/ngx';
import { FirebaseAnalytics } from '@ionic-native/firebase-analytics/ngx';
import { FirebaseMessaging } from '@ionic-native/firebase-messaging/ngx';
import { GoogleAnalytics } from '@ionic-native/google-analytics/ngx';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { BecasEstudiantesService } from './services/becas-estudiantes/becas-estudiantes.service';
import { BecasMaterialesService } from './services/becas-materiales/becas-materiales.service';
import { BibliotecaService } from './services/biblioteca/biblioteca.service';
import { CuponesService } from './services/cupones/cupones.service';
import { GuaraniService } from './services/guarani/guarani.service';
import { HerramientasService } from './services/herramientas/herramientas.service';
import { HttpInterceptorService } from './services/http-interceptor/http-interceptor.service';
import { MensajeriaService } from './services/mensajeria/mensajeria.service';
import { MoodleService } from './services/moodle/moodle.service';
import { NotificacionesService } from './services/notificaciones/notificaciones.service';
import { NuxeoService } from './services/nuxeo/nuxeo.service';
import { PresupuestoParticipativoService } from './services/presupuesto-participativo/presupuesto-participativo.service';
import { RegistroVacunacionService } from './services/registro-vacunacion/registro-vacunacion.service';
import { SolicitudIngresoCampusService } from './services/solicitud-ingreso-campus/solicitud-ingreso-campus.service';
import { SolicitudReintegroGastosService } from './services/solicitud-reintegro-gastos/solicitud-reintegro-gastos.service';
import { RadService } from './services/rad/rad.service';
import { SsoService } from './services/sso/sso.service';
import { config_firebase } from './services/config/firebase';
import * as firebase_app from 'firebase/app';

import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
import { ConfigService } from './services/config/ungs_activa_mobile_config';

const socketConfig: SocketIoConfig = { url: ConfigService.API_URL, options: { secure: true } };
registerLocaleData(localeEsAr, 'es-AR');
firebase_app.initializeApp(config_firebase);

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    SocketIoModule.forRoot(socketConfig),
    AppRoutingModule,
    HttpClientModule,
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'es-AR' },
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true
    },
    AppVersion,
    BarcodeScanner,
    Camera,
    CuponesService,
    Device,
    File,
    FirebaseAnalytics,
    FirebaseMessaging,
    GoogleAnalytics,
    GuaraniService,
    HerramientasService,
    MensajeriaService,
    MoodleService,
    NotificacionesService,
    NuxeoService,
    RadService,
    SsoService,
    BecasMaterialesService,
    BecasEstudiantesService,
    PresupuestoParticipativoService,
    BibliotecaService,
    RegistroVacunacionService,
    SolicitudIngresoCampusService,
    SolicitudReintegroGastosService,
    StatusBar,
    SplashScreen
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
