import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from '../config/ungs_activa_mobile_config';

@Injectable({
  providedIn: 'root'
})
export class GuaraniService {

  config = ConfigService;
  constructor(
    private http: HttpClient
  ) { }

  buscarPersonaPorDni(instancia: string, nroDocumento: string): Promise<{}> {
    return new Promise((resolve, reject) => {
      this.http.get(this.config.API_URL + '/guarani/' + instancia + '/personas/' + nroDocumento)
        .toPromise()
        .then((response: any) => resolve(response[0]))
        .catch((error: any) => reject(error));
    });
  }
  traerNacionalidades(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get(this.config.API_URL + '/guarani/grado/nacionalidades')
        .toPromise()
        .then((response: any) => resolve(response))
        .catch((error: any) => reject(error));
    });
  }
  traerPaises(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get(this.config.API_URL + '/guarani/grado/paises')
        .toPromise()
        .then((response: any) => resolve(response))
        .catch((error: any) => reject(error));
    });

  }
  traerTiposDocumentos(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get(this.config.API_URL + '/guarani/grado/tipos_documentos')
        .toPromise()
        .then((response: any) => resolve(response))
        .catch((error: any) => reject(error));
    });

  }
  traerCarreras(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get(this.config.API_URL + '/guarani/grado/carreras')
        .toPromise()
        .then((response: any) => resolve(response))
        .catch((error: any) => reject(error));
    });

  }
  traerMaterias(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get(this.config.API_URL + '/guarani/grado/materias')
        .toPromise()
        .then((response: any) => resolve(response))
        .catch((error: any) => reject(error));
    });

  }
  traerRequisitos(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get(this.config.API_URL + '/guarani/grado/requisitos')
        .toPromise()
        .then((response: any) => resolve(response))
        .catch((error: any) => reject(error));
    });

  }
  traerRequisitosCarreras(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get(this.config.API_URL + '/guarani/grado/requisitos_carreras')
        .toPromise()
        .then((response: any) => resolve(response))
        .catch((error: any) => reject(error));
    });

  }
  traerResponsablesAcademica(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get(this.config.API_URL + '/guarani/grado/responsables_academica')
        .toPromise()
        .then((response: any) => resolve(response))
        .catch((error: any) => reject(error));
    });

  }
  traerDatosCensalesPersona(instancia: string, nroDocumento: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get(this.config.API_URL + '/guarani/' + instancia + '/personas/' + nroDocumento + '/datos_censales')
        .toPromise()
        .then((response: any) => resolve(response))
        .catch((error: any) => reject(error));
    });

  }
  traerAlumno(instancia: string, nroDocumento: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get(this.config.API_URL + '/guarani/' + instancia + '/alumnos/' + nroDocumento)
        .toPromise()
        .then((response: any) => resolve(response))
        .catch((error: any) => reject(error));
    });

  }
  traerCursada(instancia: string, nroDocumento: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get(this.config.API_URL + '/guarani/' + instancia + '/alumnos/' + nroDocumento + '/materias')
        .toPromise()
        .then((response: any) => resolve(response))
        .catch((error: any) => reject(error));
    });

  }
  traerSituacionAcademica(instancia: string, nroDocumento: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get(this.config.API_URL + '/guarani/' + instancia + '/alumnos/' + nroDocumento + '/situacion_academica')
        .toPromise()
        .then((response: any) => resolve(response))
        .catch((error: any) => reject(error));
    });

  }
  traerPrimeraInscripcion(instancia: string, nroDocumento: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get(this.config.API_URL + '/guarani/' + instancia + '/alumnos/' + nroDocumento + '/carreras/primera_inscripcion')
        .toPromise()
        .then((response: any) => resolve(new Date(response[0].fecha)))
        .catch((error: any) => reject(error));
    });

  }
  traerCarrerasInscripto(instancia: string, nroDocumento: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get(this.config.API_URL + '/guarani/' + instancia + '/alumnos/' + nroDocumento + '/carreras/inscripto')
        .toPromise()
        .then((response: any) => resolve(response))
        .catch((error: any) => reject(error));
    });

  }
  traerInscripciones(instancia: string, nroDocumento: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get(this.config.API_URL + '/guarani/' + instancia + '/alumnos/' + nroDocumento + '/inscripciones')
        .toPromise()
        .then((response: any) => resolve(response))
        .catch((error: any) => reject(error));
    });

  }
  traerMateriasInscripto(instancia: string, nroDocumento: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get(this.config.API_URL + '/guarani/' + instancia + '/alumnos/' + nroDocumento + '/materias/inscripto')
        .toPromise()
        .then((response: any) => resolve(response))
        .catch((error: any) => reject(error));
    });

  }
  traerExamenesInscripto(instancia: string, nroDocumento: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get(this.config.API_URL + '/guarani/' + instancia + '/alumnos/' + nroDocumento + '/examenes/inscripto')
        .toPromise()
        .then((response: any) => resolve(response))
        .catch((error: any) => reject(error));
    });

  }
  traerMateriasCursando(instancia: string, nroDocumento: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get(this.config.API_URL + '/guarani/' + instancia + '/alumnos/' + nroDocumento + '/materias/cursando')
        .toPromise()
        .then((response: any) => resolve(response))
        .catch((error: any) => reject(error));
    });

  }
  traerMateriasRegularizadas(instancia: string, nroDocumento: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get(this.config.API_URL + '/guarani/' + instancia + '/alumnos/' + nroDocumento + '/materias/regularizadas')
        .toPromise()
        .then((response: any) => resolve(response))
        .catch((error: any) => reject(error));
    });

  }
  traerMateriasAprobadas(instancia: string, nroDocumento: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .get(this.config.API_URL + '/guarani/' + instancia + '/alumnos/' + nroDocumento + '/materias/aprobadas')
        .toPromise()
        .then((response: any) => resolve(response))
        .catch((error: any) => reject(error));
    });
  }
  traerBecasAsignaturas(instancia: string, dni: string, periodos_lectivos: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .get(this.config.API_URL + '/guarani/' + instancia + '/becas_materiales/asignaturas', {
          params: {
            dni: dni,
            periodos_lectivos: periodos_lectivos,
          },
        })
        .toPromise()
        .then((response: any) => resolve(response))
        .catch((error: any) => reject(error));
    });
  }
  traerListadoMensajes(instancia: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .get(this.config.API_URL + '/guarani/' + instancia + '/mensajes')
        .toPromise()
        .then((response: any) => resolve(response))
        .catch((error: any) => reject(error));
    });
  }
  traerMensaje(instancia: string, id_mensaje: number): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .get(this.config.API_URL + '/guarani/' + instancia + '/mensajes/' + id_mensaje)
        .toPromise()
        .then((response: any) => resolve(response))
        .catch((error: any) => reject(error));
    });
  }
  marcarMensajeLeido(instancia: string, id_mensaje: number): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .put(this.config.API_URL + '/guarani/' + instancia + '/mensajes/' + id_mensaje, null)
        .toPromise()
        .then((response: any) => resolve(response))
        .catch((error: any) => reject(error));
    });
  }
  traerIdentidadesGenero(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .get(this.config.API_URL + '/guarani/grado/identidad_genero')
        .toPromise()
        .then((response: any) => resolve(response))
        .catch((error: any) => reject(error));
    });
  }
}
