import { Component, NgZone } from '@angular/core';
import { Platform, LoadingController, MenuController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { SsoService } from './services/sso/sso.service';
import { HerramientasService } from './services/herramientas/herramientas.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html'
})
export class AppComponent {
  rootPage: any;
  usuario = {};
  menuItems = [];
  menuCargado = false;
  subscription: any;

  constructor(
    public herramientas: HerramientasService,
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private sso: SsoService,
    private loadingCtrl: LoadingController,
    private ngZone: NgZone,
    private router: Router,
    private menu: MenuController
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      // Okay, so the platform is ready and our plugins are available.
      // Here you can do any higher level native things you might need.
      this.menu.enable(false, 'menuPrincipal');

      this.subscription = this.sso.usuario
        .subscribe((usuario) => {
          this.ngZone.run(() => {
            this.usuario = usuario;
            if (usuario !== null) {
              if (!this.menuCargado) {
                this.sso.traerItemsMenu()
                  .then(items => {
                    this.menu.enable(true, 'menuPrincipal');
                    this.menuCargado = true;
                    this.menuItems = items;
                  })
                  .catch(error => {
                    console.error('Activa:AppComponent - traerItemsMenu()', error);
                    this.menu.enable(false, 'menuPrincipal');
                    this.menuCargado = false;
                  });
              }
            } else {
              this.menu.enable(false, 'menuPrincipal');
              this.menuCargado = false;
              this.menuItems = [];
            }
          });
        });

      if (this.herramientas.esApp()) {
        this.herramientas.ga.startTrackerWithId('UA-100742665-2')
          .catch(error => console.warn('Activa:AppComponent - startTrackerWithId()', error));
        this.statusBar.overlaysWebView(false);
        this.statusBar.backgroundColorByHexString('#E53935'); // ionic-danger
        setTimeout(() => {
          this.splashScreen.hide();
        }, 1000);
      }
    });
  }

  cambiarVista(vista: string) {
    this.router.navigateByUrl(vista);
    this.menu.close('menuPrincipal');
  }

  logout() {
    this.loadingCtrl.create({
      message: 'Cerrando sesión...'
    })
      .then(deslogueandoCreado => {
        deslogueandoCreado.present()
          .then(() => {
            this.sso.logout()
              .then(() => {
                this.menuCargado = false;
                this.cambiarVista('acceso');
                deslogueandoCreado.dismiss();
              })
              .catch(error => {
                console.error('Activa:AppComponent - logout()', JSON.stringify(error));
                this.menuCargado = false;
                this.cambiarVista('acceso');
                deslogueandoCreado.dismiss();
              });
          })
          .catch((deslogueandoNoPresentado) => console.warn(deslogueandoNoPresentado));

      })
      .catch(deslogueandoNoCreado => console.warn(deslogueandoNoCreado));
  }

  abrirItemMenu(item: { href: string }) {
    this.sso.loginModulos(this.herramientas.storage().getItem('UNGSxt_session'), item.href, '_blank', this.herramientas.esBrowser());
  }
}
