import { Injectable } from '@angular/core';
import { ConfigService } from '../config/ungs_activa_mobile_config';
import { BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { AlertController } from '@ionic/angular';
import { NotificacionesService } from '../notificaciones/notificaciones.service';

@Injectable({
  providedIn: 'root'
})
export class MensajeriaService {

  config = ConfigService;
  resumen: any = new BehaviorSubject(null);
  mensajes: any = new BehaviorSubject(null);
  desktopSubscription: any;
  nativeSubscription: any;

  constructor(
    private http: HttpClient,
    private alertCtrl: AlertController,
    private notificacionesService: NotificacionesService
  ) { }

  desktop_onMessage() {
    // this.notificacionesService.desktop_onNotification();
    this.desktopSubscription = this.notificacionesService.desktopNotification.subscribe((payload) => {
      if (payload) {
        // si el mensaje es manual y mensaje personal o general, se muestra
        if (payload.data.tipo_envio === '2' &&
          (payload.data.tipo_notificacion === '1' ||
            payload.data.tipo_notificacion === '2')) {

          this.alertCtrl.create({
            header: payload.data.titulo,
            message: payload.data.mensaje,
            buttons: ['Aceptar']
          })
            .then(alert => alert.present())
            .catch(alertNoCreada => console.warn(alertNoCreada));

          this.marcarLeido(payload.data.id_registro)
            .then((resultado) => {
              this.traerResumen()
                .then(resumen => this.resumen.next(resumen))
                .catch(error => console.error(error));

              this.traerListadoMensajes()
                .then(mensajes => this.mensajes.next(mensajes))
                .catch(error => console.error(error));
            })
            .catch(error => console.error(error));
        }
      }
    });
  }

  desktop_unsubscribeOnMessage() {
    return this.desktopSubscription.unsubscribe();
  }

  native_onMessage() {

    // this.notificacionesService.native_onNotification();
    this.nativeSubscription = this.notificacionesService.nativeNotification.subscribe(data => {
      if (data.tipo_envio === '2' &&
        (data.tipo_notificacion === '1' ||
          data.tipo_notificacion === '2')) {

        this.alertCtrl.create({
          header: data.titulo,
          message: data.mensaje,
          buttons: ['Aceptar']
        })
          .then(alert => alert.present())
          .catch(alertNoCreada => console.warn(alertNoCreada));

        this.marcarLeido(data.id_registro)
          .then((resultado) => {
            this.traerResumen()
              .then(resumen => this.resumen.next(resumen))
              .catch(error => console.error(error));

            this.traerListadoMensajes()
              .then(mensajes => this.mensajes.next(mensajes))
              .catch(error => console.error(error));
          })
          .catch(error => console.error(error));
      }

      if (data.wasTapped) {
        console.log('Received in background');
      } else {
        console.log('Received in foreground');
      }
    });
  }

  native_unsubscribeOnMessage() {
    return this.nativeSubscription.unsubscribe();
  }

  traerResumen(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get(this.config.API_URL + '/ungs_activa/notificaciones/resumen')
        .toPromise()
        .then((response: any) => resolve(response))
        .catch((error: any) => reject(error));
    });
  }

  traerListadoMensajes(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get(this.config.API_URL + '/ungs_activa/notificaciones/listado')
        .toPromise()
        .then((response: any) => resolve(response))
        .catch((error: any) => reject(error));
    });
  }

  traerMensaje(idMensaje: number): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get(this.config.API_URL + '/ungs_activa/notificaciones/mensajes/' + idMensaje)
        .toPromise()
        .then((response: any) => resolve(response))
        .catch((error: any) => reject(error));
    });
  }

  marcarLeido(idMensaje: number): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.put(this.config.API_URL + '/ungs_activa/notificaciones/mensajes/' + idMensaje, null)
        .toPromise()
        .then((response: any) => {
          this.traerResumen()
            .then(resumen => this.resumen.next(resumen))
            .catch(error => console.error(error));

          this.traerListadoMensajes()
            .then(mensajes => this.mensajes.next(mensajes))
            .catch(error => console.error(error));

          resolve(response);
        })
        .catch((error: any) => reject(error));
    });
  }
}
