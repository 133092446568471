import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from '../config/ungs_activa_mobile_config';
import { HerramientasService } from '../herramientas/herramientas.service';

@Injectable({
  providedIn: 'root'
})
export class RegistroVacunacionService {

  config = ConfigService;

  constructor(private http: HttpClient, public herramientas: HerramientasService) {}

  traerTiposAcredita(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .get(this.config.API_URL + '/ungs_activa/registro_vacunacion/tipos_acredita')
        .toPromise()
        .then((response: any) => resolve(response))
        .catch((error: any) => reject(error));
    });
  }

  traerMotivos(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .get(this.config.API_URL + '/ungs_activa/registro_vacunacion/motivos')
        .toPromise()
        .then((response: any) => resolve(response))
        .catch((error: any) => reject(error));
    });
  }

  traerRegistros(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .get(this.config.API_URL + '/ungs_activa/registro_vacunacion/registros')
        .toPromise()
        .then((response: any) => resolve(response))
        .catch((error: any) => reject(error));
    });
  }

  guardarRegistro(registro: object): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .post(this.config.API_URL + '/ungs_activa/registro_vacunacion/registros', registro)
        .toPromise()
        .then((response: any) => resolve(response))
        .catch((error: any) => reject(error));
    });
  }
}
