import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Device } from '@ionic-native/device/ngx';
import { Platform } from '@ionic/angular';
import { ConfigService } from '../config/ungs_activa_mobile_config';
import { HerramientasService } from '../herramientas/herramientas.service';

@Injectable({
  providedIn: 'root',
})
export class PresupuestoParticipativoService {
  config = ConfigService;

  constructor(
    private http: HttpClient,
    private device: Device,
    private platform: Platform, 
    public herramientas: HerramientasService
    ) {}

  traerPeriodos(filtro?: any): Promise<any> {
    let url = filtro.noauth === true ? this.config.API_URL + '/presupuesto_participativo/no_auth/periodos' : this.config.API_URL + '/presupuesto_participativo/periodos';
    delete filtro.noauth;
    return new Promise((resolve, reject) => {
      this.http
        .get(url, { params: filtro })
        .toPromise()
        .then((response: any) => resolve(response))
        .catch((error: any) => reject(error));
    });
  }

  traerMisClaustros(filtro?: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .get(this.config.API_URL + '/presupuesto_participativo/traer_mis_clautros', { params: filtro })
        .toPromise()
        .then((response: any) => resolve(response))
        .catch((error: any) => reject(error));
    });
  }

  habilitadoVotar(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .get(this.config.API_URL + '/presupuesto_participativo/habilitado_votar')
        .toPromise()
        .then((response: any) => resolve(response))
        .catch((error: any) => reject(error));
    });
  }

  traerPropuestas(filtro?: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .get(this.config.API_URL + '/presupuesto_participativo/propuestas', { params: filtro })
        .toPromise()
        .then((response: any) => resolve(response))
        .catch((error: any) => reject(error));
    });
  }

  traerVotantes(filtro?: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .get(this.config.API_URL + '/presupuesto_participativo/votantes', { params: filtro })
        .toPromise()
        .then((response: any) => resolve(response))
        .catch((error: any) => reject(error));
    });
  }

  emitioVoto(filtro?: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .get(this.config.API_URL + '/presupuesto_participativo/emitio_voto', { params: filtro })
        .toPromise()
        .then((response: any) => resolve(response))
        .catch((error: any) => reject(error));
    });
  }

  votar(voto: Object) {
    const device = this.device;
    const platform = this.platform;

    voto['platforms'] = platform.platforms();
    voto['device'] = {
      manufacturer: device.manufacturer,
      model: device.model,
      platform: device.platform,
      serial: device.serial,
      uuid: device.uuid,
    };

    return new Promise((resolve, reject) => {
      this.http
        .post(this.config.API_URL + '/presupuesto_participativo/votar', voto)
        .toPromise()
        .then((response: any) => resolve(response))
        .catch((error: any) => reject(error));
    });
  }
}
