import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from '../config/ungs_activa_mobile_config';
import { HerramientasService } from '../herramientas/herramientas.service';

@Injectable({
  providedIn: 'root'
})
export class SolicitudReintegroGastosService {

  config = ConfigService;

  constructor(private http: HttpClient, public herramientas: HerramientasService) {}

  traerConfiguracionGastos(filtro?: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .get(this.config.API_URL + '/ungs_activa/solicitud_reintegro_gastos/config_gastos', { params: filtro })
        .toPromise()
        .then((response: any) => resolve(response))
        .catch((error: any) => reject(error));
    });
  }

  traerTotalSolicitado(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .get(this.config.API_URL + '/ungs_activa/solicitud_reintegro_gastos/total_solicitado')
        .toPromise()
        .then((response: any) => resolve(response))
        .catch((error: any) => reject(error));
    });
  }

  traerSolicitudes(filtro?: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .get(this.config.API_URL + '/ungs_activa/solicitud_reintegro_gastos/solicitudes', { params: filtro })
        .toPromise()
        .then((response: any) => resolve(response))
        .catch((error: any) => reject(error));
    });
  }

  guardarSolicitud(solicitud: object) {
    return new Promise((resolve, reject) => {
      this.http
        .post(this.config.API_URL + '/ungs_activa/solicitud_reintegro_gastos/solicitudes', solicitud)
        .toPromise()
        .then((response: any) => resolve(response))
        .catch((error: any) => reject(error));
    });
  }
}
