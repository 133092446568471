import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from '../config/ungs_activa_mobile_config';
import { HerramientasService } from '../herramientas/herramientas.service';
import { NotificacionesService } from '../notificaciones/notificaciones.service';
import { NuxeoService } from '../nuxeo/nuxeo.service';
import { MoodleService } from '../moodle/moodle.service';
import { FirebaseAnalytics } from '@ionic-native/firebase-analytics/ngx';
import * as firebase_app from 'firebase/app';
import 'firebase/auth';
import { BibliotecaService } from '../biblioteca/biblioteca.service';

@Injectable({
  providedIn: 'root',
})
export class SsoService {
  config = ConfigService;
  usuario: any = new BehaviorSubject(null);
  auth: firebase.auth.Auth;
  persistence: Promise<any>;
  claustrosActualizados = false;
  logueadoEn = {
    firebase: false,
    sso: false,
    nuxeo: false,
    moodle: false,
    biblioteca: false
  };

  constructor(
    private http: HttpClient,
    private nuxeo: NuxeoService,
    private moodle: MoodleService,
    private biblioteca: BibliotecaService,
    private firebaseAnalytics: FirebaseAnalytics,
    public herramientas: HerramientasService,
    private notificacion: NotificacionesService
  ) {
    this.auth = firebase_app.auth();
    this.persistence = this.auth.setPersistence(firebase_app.auth.Auth.Persistence.SESSION);
    if (this.herramientas.esApp()) {
      this.persistence = this.auth.setPersistence(firebase_app.auth.Auth.Persistence.LOCAL);
    }
  }

  login(usernameValue: string, passwordValue: string): Promise<any> {
    let usuarioFirebaseValue;
    return new Promise((resolve, reject) => {
      if (!usernameValue) {
        reject('Debe completar el nombre de usuario');
      }
      if (!passwordValue) {
        reject('Debe completar la clave');
      }

      this.persistence
        .then(() => {
          this.auth
            .signInWithEmailAndPassword(usernameValue, passwordValue)
            .then((registro) => {
              if (registro) {
                this.logueadoEn.firebase = true;
                usuarioFirebaseValue = registro;

                registro.user
                  .getIdToken()
                  .then((token) => {
                    const requestParams = {
                      fromApp: this.herramientas.esApp(),
                      idToken: token,
                      username: usernameValue,
                      password: passwordValue,
                    };
                    this.http
                      .post(this.config.API_URL + '/firebase/login', requestParams)
                      .toPromise()
                      .then((response: any) => {
                        try {
                          this.logueadoEn.sso = true;
                          this.herramientas.storage().setItem('UNGSxt_session', response.UNGSxt_session);
                          this.herramientas.storage().setItem('UNGSXTuser', JSON.stringify(response.UNGSXTuser));

                          if (this.herramientas.esApp()) {
                            this.firebaseAnalytics.setUserId(response.UNGSXTuser.id);
                          }

                          this.setUpdateToken();
                          this.postLogin();

                          setTimeout(() => {
                            this.usuario.next(response.UNGSXTuser);
                          }, 0);

                          resolve({
                            usuarioFirebase: usuarioFirebaseValue,
                            usuario_ungsxt: response.UNGSXTuser,
                          });
                        } catch (error) {
                          console.error(error);
                          reject(error);
                        }
                      })
                      .catch((error: any) => {
                        console.error('Activa:ssoService - login():' + JSON.stringify(error));
                        this.clear();
                        reject(error);
                      });
                  })
                  .catch((error: any) => {
                    console.error('Activa:ssoService - getIdToken():' + error);
                    this.clear();
                    reject(error);
                  });
              } else {
                reject('Error en API Firebase');
              }
            })
            .catch((error) => {
              console.error('Activa:ssoService - signInWithEmailAndPassword():' + error);
              this.clear();
              reject(error);
            });
        })
        .catch((error) => {
          console.error('Activa:ssoService - setPersistence():' + error);
          this.clear();
          reject(error);
        });
    });
  }

  validarUsuario(usernameValue: string, passwordValue: string): Promise<any> {
    return new Promise((resolve, reject) => {
      if (!usernameValue) {
        reject('Debe completar el nombre de usuario');
      }
      if (!passwordValue) {
        reject('Debe completar la clave');
      }

      this.auth
        .signInWithEmailAndPassword(usernameValue, passwordValue)
        .then((registro) => {
          resolve(registro);
        })
        .catch((error) => {
          // console.error('Activa:ssoService - signInWithEmailAndPassword():' + error);
          reject(error);
        });
    });
  }

  postLogin() {
    this.herramientas.allSettled([
      this.actualizarClaustros(), 
      this.moodle.login(), 
      this.nuxeo.login(),
      this.biblioteca.login()
    ]).then((resultados) => {
      if (resultados[0].state === 'fulfilled') {
        this.claustrosActualizados = true;
      } else {
        console.warn('Activa:ssoService - actualizarClaustros():' + JSON.stringify(resultados[0]['reason']));
      }

      if (resultados[1].state === 'fulfilled') {
        this.logueadoEn.moodle = true;
      } else {
        console.warn('Activa:ssoService - moodle.login():' + JSON.stringify(resultados[1]['reason']));
      }

      if (resultados[2].state === 'fulfilled') {
        this.logueadoEn.nuxeo = true;
      } else {
        console.warn('Activa:ssoService - nuxeo.login():' + JSON.stringify(resultados[2]['reason']));
      }

      if (resultados[3].state === 'fulfilled') {
        this.logueadoEn.biblioteca = true;
      } else {
        console.warn('Activa:ssoService - biblioteca.login():' + JSON.stringify(resultados[2]['reason']));
      }

      this.sesion().catch((error) => console.error('Activa:ssoService - sesion():' + JSON.stringify(error)));
    });
  }

  logout(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.claustrosActualizados = false;
      this.logueadoEn.firebase = false;
      this.logueadoEn.sso = false;
      this.logueadoEn.moodle = false;
      this.logueadoEn.nuxeo = false;

      if (this.herramientas.esBrowser()) {
        this.notificacion.desktop_unsubscribeOnNotification();
      } else {
        this.notificacion.native_unsubscribeOnNotification();
      }

      this.http
        .post(this.config.API_URL + '/firebase/logout', null, {})
        .toPromise()
        .then(() => {
          setTimeout(() => {
            this.usuario.next(null);
          }, 0);

          this.clear()
            .then(() => resolve('Deslogueado'))
            .catch((error) => console.log('Activa:ssoService - clear():' + JSON.stringify(error)));
        })
        .catch((errorLogout) => {
          console.error('No Logueado');
          setTimeout(() => {
            this.usuario.next(null);
          }, 0);

          this.clear()
            .then(() => reject('No Logueado'))
            .catch((error) => console.log('Activa:ssoService - clear():' + JSON.stringify(error)));
        });
    });
  }

  clear(): Promise<any> {
    this.herramientas.storage().clear();
    return this.auth.signOut();
  }

  sesion(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .get(this.config.API_URL + '/ungsxt/sesion')
        .toPromise()
        .then((response: any) => {
          if (typeof response.areaActiva === 'string') {
            response.areaActiva = JSON.parse(response.areaActiva);
          }
          if (typeof response.claustros === 'string') {
            response.claustros = JSON.parse(response.claustros);
          }
          if (typeof response.dependencias === 'string') {
            response.dependencias = JSON.parse(response.dependencias);
          }
          this.herramientas.storage().setItem('UNGSXTuser', JSON.stringify(response));
          setTimeout(() => {
            this.usuario.next(response);
          }, 0);
          resolve(response);
        })
        .catch((error: any) => reject(error));
    });
  }

  estaLogueado(): Promise<any> {
    return new Promise((resolve, reject) => {
      if (this.herramientas.storage().getItem('UNGSxt_session') !== null) {
        this.logueadoEn.firebase = true;
        this.sesion()
          .then(() => {
            this.logueadoEn.sso = true;

            const usuario = JSON.parse(this.herramientas.storage().getItem('UNGSXTuser'));

            if (typeof usuario.areaActiva === 'string') {
              usuario.areaActiva = JSON.parse(usuario.areaActiva);
            }
            if (typeof usuario.claustros === 'string') {
              usuario.claustros = JSON.parse(usuario.claustros);
            }
            if (typeof usuario.dependencias === 'string') {
              usuario.dependencias = JSON.parse(usuario.dependencias);
            }

            setTimeout(() => {
              this.usuario.next(usuario);
            }, 0);

            resolve('Logueado');
          })
          .catch(() => {
            this.logout().catch((error) => console.warn('Activa:ssoService - logout():' + JSON.stringify(error)));
            reject('No Logueado en Servidor');
          });
      } else {
        reject('No logueado en Cliente');
      }
    });
  }

  buscarPersona(documento: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .get(this.config.API_URL + '/ungsxt/usuarios/dni/' + documento)
        .toPromise()
        .then((response: any) => resolve(response))
        .catch((error: any) => {
          if (error.error.message === 'usuario inexistente') {
            this.herramientas
              .allSettled([
                this.http.get(this.config.API_URL + '/ungsxt/signup/' + documento).toPromise(),
                this.http.get(this.config.API_URL + '/moodle/grado/usuario/' + documento).toPromise(),
                this.http.get(this.config.API_URL + '/moodle/posgrado/usuario/' + documento).toPromise(),
                this.http.get(this.config.API_URL + '/moodle/secundaria/usuario/' + documento).toPromise(),
              ])
              .then((responses: any) => {
                const resp = responses.map((r) => r.value).filter((r) => r !== undefined && r !== null && r.length > 0);

                if (resp.length > 0) {
                  if (resp[0].length > 0) {
                    resolve(resp[0][0]);
                  } else {
                    resolve(null);
                  }
                } else {
                  resolve(null);
                }
              })
              .catch((errorPromiseAll: any) => reject(errorPromiseAll));
          } else {
            reject(error);
          }
        });
    });
  }

  traerClaustrosHumanosUNGS(nroDocumento: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .get(this.config.API_URL + '/ungsxt/signup/' + nroDocumento)
        .toPromise()
        .then((response: any) => resolve(response))
        .catch((error: any) => reject(error));
    });
  }

  traerClaustros(id?: number): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = this.config.API_URL + '/ungsxt/claustros';
      if (id) {
        url += `/${id}`;
      }
      this.http
        .get(url)
        .toPromise()
        .then((response: any) => resolve(response))
        .catch((error: any) => reject(error));
    });
  }

  actualizarClaustros(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .post(this.config.API_URL + '/ungsxt/claustros/actualizar', null, {
          responseType: 'text' as 'json',
        })
        .toPromise()
        .then((response: any) => resolve(response))
        .catch((error: any) => reject(error));
    });
  }

  enviarConfirmacion(persona: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .post(this.config.API_URL + '/ungsxt/registro/enviar_confirmacion', persona)
        .toPromise()
        .then((response: any) => resolve(response))
        .catch((error: any) => reject(error));
    });
  }

  reenviarConfirmacion(persona: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .post(this.config.API_URL + '/ungsxt/registro/reenviar_confirmacion', persona)
        .toPromise()
        .then((response: any) => resolve(response))
        .catch((error: any) => reject(error));
    });
  }

  validarCodigo(persona: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .post(this.config.API_URL + '/ungsxt/registro/validar_confirmacion', persona)
        .toPromise()
        .then((response: any) => resolve(response))
        .catch((error: any) => reject(error));
    });
  }

  finalizarRegistro(persona: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .post(this.config.API_URL + '/ungsxt/registro/finalizar_registro', persona)
        .toPromise()
        .then((response: any) => resolve(response))
        .catch((error: any) => reject(error));
    });
  }

  actualizarUsuario(usernameValue: string, passwordValue: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .post(this.config.API_URL + '/ungsxt/registro/actualizar_usuario', { username: usernameValue, password: passwordValue })
        .toPromise()
        .then((response: any) => resolve(response))
        .catch((error: any) => reject(error));
    });
  }

  enviarCodigoRecupero(usuarioValue: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .post(this.config.API_URL + '/ungsxt/recupero_clave/enviar_codigo', { usuario: usuarioValue })
        .toPromise()
        .then((response: any) => resolve(response))
        .catch((error: any) => reject(error));
    });
  }

  validarCodigoRecupero(persona: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .post(this.config.API_URL + '/ungsxt/recupero_clave/validar_codigo', persona)
        .toPromise()
        .then((response: any) => resolve(response))
        .catch((error: any) => reject(error));
    });
  }

  cambiarClaveRecupero(persona: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .put(this.config.API_URL + '/ungsxt/recupero_clave/cambiar_clave', persona)
        .toPromise()
        .then((response: any) => resolve(response))
        .catch((error: any) => reject(error));
    });
  }

  traerTiposConsulta(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .get(this.config.API_URL + '/ungsxt/tipos_consulta')
        .toPromise()
        .then((response: any) => resolve(response))
        .catch((error: any) => reject(error));
    });
  }

  enviarconsulta(consulta: any): Promise<any> {
    return new Promise((resolve, reject) => {
      let formData = new FormData();
      Object.keys(consulta).forEach((k) => {
        if (consulta[k] !== null) {
          if (consulta[k].data !== undefined) {
            consulta[k] = JSON.stringify(consulta[k]);
          }
          formData.append(k, consulta[k]);
        }
      });
      this.http
        .post(this.config.API_URL + '/ungsxt/ssohelp', formData)
        .toPromise()
        .then((response: any) => resolve(response))
        .catch((error: any) => reject(error));
    });
  }

  cambiarClave(password: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .put(this.config.API_URL + '/ungsxt/cambiar_clave', password)
        .toPromise()
        .then((response: any) => resolve(response))
        .catch((error: any) => reject(error));
    });
  }

  enviarCodigoCambioEmail(nuevoMail: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .post(this.config.API_URL + '/ungsxt/cambio_email/enviar_codigo', { email: nuevoMail })
        .toPromise()
        .then((response: any) => resolve(response))
        .catch((error: any) => reject(error));
    });
  }

  validarCodigoCambioEmail(persona: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .post(this.config.API_URL + '/ungsxt/cambio_email/validar_codigo', persona)
        .toPromise()
        .then((response: any) => resolve(response))
        .catch((error: any) => reject(error));
    });
  }

  cambiarEmail(persona: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .put(this.config.API_URL + '/ungsxt/cambio_email/cambiar_email', persona)
        .toPromise()
        .then((response: any) => resolve(response))
        .catch((error: any) => reject(error));
    });
  }

  loginModulos(hash: string, url: string, target: string, isBrowser: boolean) {
    if (isBrowser) {
      const form = document.createElement('form');
      form.setAttribute('action', this.config.API_URL + '/ungsxt/login_modulos');
      form.setAttribute('method', 'post');
      form.setAttribute('target', target);
      const inputHash = document.createElement('input');
      inputHash.setAttribute('type', 'hidden');
      inputHash.setAttribute('name', 'hash');
      inputHash.setAttribute('value', hash);
      const inputUrl = document.createElement('input');
      inputUrl.setAttribute('type', 'hidden');
      inputUrl.setAttribute('name', 'url');
      inputUrl.setAttribute('value', url);

      form.appendChild(inputHash);
      form.appendChild(inputUrl);

      document.body.appendChild(form);
      form.submit();
      document.body.removeChild(form);
    } else {
      window.open(this.config.API_URL + '/ungsxt/login_modulos?url=' + btoa(url) + '&hash=' + hash, '_system');
    }
  }

  encuestaDocente(hash: string, target: string, isBrowser: boolean) {
    if (isBrowser) {
      const form = document.createElement('form');
      form.setAttribute('action', this.config.API_URL + '/ungsxt/abrir_encuesta_docente');
      form.setAttribute('method', 'post');
      form.setAttribute('target', target);
      const inputHash = document.createElement('input');
      inputHash.setAttribute('type', 'hidden');
      inputHash.setAttribute('name', 'hash');
      inputHash.setAttribute('value', hash);

      form.appendChild(inputHash);

      document.body.appendChild(form);
      form.submit();
      document.body.removeChild(form);
    } else {
      window.open(this.config.API_URL + '/ungsxt/abrir_encuesta_docente?hash=' + hash, '_system');
    }
  }

  videoconferencias(url: string) {
    window.open(encodeURI(url), '_system');
  }

  traerItemsMenu(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .get(this.config.API_URL + '/ungsxt/menu/items')
        .toPromise()
        .then((response: any) => resolve(response))
        .catch((error: any) => reject(error));
    });
  }

  setUpdateToken() {
    if (this.herramientas.esBrowser()) {
      if (this.notificacion.estaSoportado) {
        this.notificacion
          .desktop_requestPermission()
          .then(() => {
            console.log('Se concedió permisos de Notificación.');
            this.notificacion.desktop_getToken();
            this.notificacion.desktop_onNotification();
            setTimeout(() => this.notificacion.desktop_onTokenRefresh(), 2500);
          })
          .catch((error) => console.warn('No se concedió permisos de Notificación', JSON.stringify(error)));
      }
    } else {
      this.notificacion.native_getToken();
      this.notificacion.native_onNotification();
      setTimeout(() => this.notificacion.native_onTokenRefresh(), 2500);
    }
  }

  buscarUsuario(busqueda: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .get(this.config.API_URL + '/sso_gestion/usuarios?searchQry=' + busqueda + '&limit=25&offset=0')
        .toPromise()
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  }

  descargarArchivosRRHH(nombre_archivo): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .get(this.config.API_URL + '/ungs_activa/rrhh/documentacion/descarga?nombre_archivo=' + nombre_archivo, {
          responseType: 'arraybuffer',
        })
        .toPromise()
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    });
  }

  descargarArchivosRRHHURL(nombre_archivo): string {
    return this.config.API_URL + '/ungs_activa/rrhh/documentacion/descarga?nombre_archivo=' + nombre_archivo;
  }

  descargarArchivosActivaURL(nombre_archivo): string {
    return this.config.API_URL + '/ungs_activa/documentacion/descarga?nombre_archivo=' + nombre_archivo;
  }

  traerQR(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .get(this.config.API_URL + '/ungs_activa/usuario/qr', { responseType: 'text' })
        .toPromise()
        .then((response: any) => resolve(response))
        .catch((error: any) => reject(error));
    });
  }

  obtenerItems(vista): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .get(this.config.API_URL + '/ungs_activa/usuario/obtener_items' + '?vista=' + vista.nombre)
        .toPromise()
        .then((response: any) => resolve(response))
        .catch((error: any) => reject(error));
    });
  }

  traerAreas(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .get(this.config.API_URL + '/ungs_activa/areas')
        .toPromise()
        .then((response: any) => resolve(response))
        .catch((error: any) => reject(error));
    });
  }
}
