import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from '../config/ungs_activa_mobile_config';

@Injectable({
  providedIn: 'root'
})
export class BibliotecaService {

  config = ConfigService;

  constructor(
    private http: HttpClient
  ) { }

  login(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.post(this.config.API_URL + '/biblioteca/login', null)
        .toPromise()
        .then((response: Response) => resolve('Moodle logueado'))
        .catch((error: Response) => reject('Moodle No logueado'));
    });
  }
}
