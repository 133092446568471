import { Injectable } from '@angular/core';
import { ConfigService } from '../config/ungs_activa_mobile_config';
import { HttpClient } from '@angular/common/http';
import { HerramientasService } from '../herramientas/herramientas.service';

@Injectable({
  providedIn: 'root'
})
export class NuxeoService {

  config = ConfigService;

  constructor(
    private http: HttpClient,
    public herramientas: HerramientasService
  ) { }

  login(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.post(this.config.API_URL + '/cmis/login', null)
        .toPromise()
        .then((response: any) => resolve('Nuxeo logueado'))
        .catch((error: any) => reject('Nuxeo No logueado'));
    });
  }

  traerObjeto(objectId: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .get(this.config.API_URL + '/cmis/objeto/' + objectId)
        .toPromise()
        .then((response: any) => resolve(response))
        .catch((error: any) => reject(error))
    })
  }

  descargar(objectId: string, nombre?: string): Promise<any> {
    let params = {}
    if (nombre) {
      params['nombre'] = nombre
    }
    return new Promise((resolve, reject) => {
      this.http
        .get(this.config.API_URL + '/cmis/objeto/' + objectId + '/descargar', { params: params, responseType: 'text' })
        .toPromise()
        .then((response: any) => resolve(response))
        .catch((error: any) => reject(error))
    })
  }

  eliminarObjeto(objectId: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.delete(this.config.API_URL + '/cmis/objeto/' + objectId)
        .toPromise()
        .then((response: any) => resolve(response))
        .catch((error: any) => reject(error));
    });
  }

  eliminarImagenPerfil(): Promise<any> {
    const usuario = JSON.parse(this.herramientas.storage().getItem('UNGSXTuser'));
    const query = 'SELECT * FROM cmis:document WHERE cmis:name = \'profile\' AND nuxeo:parentId =\'' + usuario.idCarpetaPersonal + '\'';
    return new Promise((resolve, reject) => {
      this.http.post(this.config.API_URL + '/cmis/query', {
        statement: query
      })
        .toPromise()
        .then((response: any) => {
          if (response.numItems > 0) {
            this.eliminarObjeto(response.results[0].succinctProperties['cmis:objectId'])
              .then(() => {
                delete usuario.URLFotoPerfil;
                this.herramientas.storage().setItem('UNGSXTuser', JSON.stringify(usuario));
              })
              .catch((error: any) => console.error(error));
          }
          resolve(response);
        })
        .catch((error: any) => reject(error));
    });
  }

  subirImagenBase64(objectId: string, imagen: object): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.post(this.config.API_URL + '/cmis/objeto/' + objectId + '/base64', imagen)
        .toPromise()
        .then((response: any) => resolve(response))
        .catch((error: any) => reject(error));
    });
  }
}
