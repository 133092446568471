import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { SsoService } from '../services/sso/sso.service';
import { ConfigService } from '../services/config/ungs_activa_mobile_config';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  config = ConfigService;
  constructor(
    public router: Router,
    public sso: SsoService
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<boolean> {
    return this.sso.estaLogueado()
      .then(logueado => true)
      .catch(nologueado => {
        if(state.url) {
          this.router.navigate(['/acceso'], {queryParams: {redirect: btoa(`${this.config.HOME_URL}${state.url}`)}});
        } else {
          this.router.navigate(['/acceso'], {queryParamsHandling: 'preserve', preserveFragment: true});
        }
        return false;
      });
  }
}
