import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';

const routes: Routes = [
  { path: '401', loadChildren: './pages/unauthorized/unauthorized.module#UnauthorizedPageModule' },
  { path: '404', loadChildren: './pages/not-found/not-found.module#NotFoundPageModule' },
  { path: '500', loadChildren: './pages/internal-error/internal-error.module#InternalErrorPageModule' },
  { path: 'acceso', loadChildren: './pages/acceso/acceso.module#AccesoPageModule' },
  { path: 'actividades', canActivate: [AuthGuard], loadChildren: './pages/act-actividades/act-actividades.module#ActActividadesPageModule' },
  { path: 'actividades/actividad', canActivate: [AuthGuard], loadChildren: './pages/act-actividad/act-actividad.module#ActActividadPageModule' },
  { path: 'asistencias', canActivate: [AuthGuard], loadChildren: './pages/act-asistencias/act-asistencias.module#ActAsistenciasPageModule' },
  { path: 'asistencias/asistencia', canActivate: [AuthGuard], loadChildren: './pages/act-asistencia/act-asistencia.module#ActAsistenciaPageModule' },
  { path: 'asistencias/importar-asistencia', canActivate: [AuthGuard], loadChildren: './pages/act-asistencia-importar/act-asistencia-importar.module#ActAsistenciaImportarPageModule' },
  { path: 'asistencias/info-creditos', canActivate: [AuthGuard], loadChildren: './pages/act-asistencias-info-creditos/act-asistencias-info-creditos.module#ActAsistenciasInfoCreditosPageModule' },
  // { path: 'actividades/actividad-vista/:id', canActivate: [AuthGuard], loadChildren: './pages/act-actividad-vista/act-actividad-vista.module#ActActividadVistaPageModule' },
  // { path: 'actividades', canActivate: [AuthGuard], loadChildren: './pages/actividades/actividades.module#ActividadesPageModule' },
  // { path: 'actividades/busqueda/:uidActividad', canActivate: [AuthGuard], loadChildren: './pages/actividades-busqueda/actividades-busqueda.module#ActividadesBusquedaPageModule' },
  // { path: 'actividades/importar/:uidActividad', canActivate: [AuthGuard], loadChildren: './pages/actividades-importar/actividades-importar.module#ActividadesImportarPageModule' },
  // { path: 'actividades/informacion/:uidActividad', canActivate: [AuthGuard], loadChildren: './pages/actividades-informacion/actividades-informacion.module#ActividadesInformacionPageModule' },
  // { path: 'actividades/participantes/:uidActividad', canActivate: [AuthGuard], loadChildren: './pages/actividades-participantes/actividades-participantes.module#ActividadesParticipantesPageModule' },
  { path: 'ajustes', canActivate: [AuthGuard], loadChildren: './pages/ajustes/ajustes.module#AjustesPageModule' },
  { path: 'ayuda', loadChildren: './pages/ayuda/ayuda.module#AyudaPageModule' },
  { path: 'becas-estudiantes', canActivate: [AuthGuard], loadChildren: './pages/becas-estudiantes/becas-estudiantes.module#BecasEstudiantesPageModule' },
  { path: 'becas-estudiantes-cbu', canActivate: [AuthGuard], loadChildren: './pages/becas-estudiantes-cbu/becas-estudiantes-cbu.module#BecasEstudiantesCbuPageModule' },
  { path: 'becas-materiales', canActivate: [AuthGuard], loadChildren: './pages/becas-materiales/becas-materiales.module#BecasMaterialesPageModule' },
  { path: 'becas-materiales-entrega', canActivate: [AuthGuard], loadChildren: './pages/becas-materiales-entrega/becas-materiales-entrega.module#BecasMaterialesEntregaPageModule' },
  { path: 'campus-ayuda', canActivate: [AuthGuard], loadChildren: './pages/ayuda/ayuda.module#AyudaPageModule' },
  { path: 'cambio-clave', canActivate: [AuthGuard], loadChildren: './pages/cambio-clave/cambio-clave.module#CambioClavePageModule' },
  { path: 'cambio-email', canActivate: [AuthGuard], loadChildren: './pages/cambio-email/cambio-email.module#CambioEmailPageModule' },
  { path: 'cambio-foto', canActivate: [AuthGuard], loadChildren: './pages/cambio-foto/cambio-foto.module#CambioFotoPageModule' },
  { path: 'cupon/:idCuponera/:idCupon', canActivate: [AuthGuard], loadChildren: './pages/cupon/cupon.module#CuponPageModule' },
  { path: 'cuponeras', canActivate: [AuthGuard], loadChildren: './pages/cuponeras/cuponeras.module#CuponerasPageModule' },
  { path: 'cupones/:idCuponera', canActivate: [AuthGuard], loadChildren: './pages/cupones/cupones.module#CuponesPageModule' },
  { path: 'fondo-solidario', canActivate: [AuthGuard], loadChildren: './pages/fondo-solidario/fondo-solidario.module#FondoSolidarioPageModule' },
  { path: 'info', canActivate: [AuthGuard], loadChildren: './pages/info/info.module#InfoPageModule' },
  { path: 'info-docente', canActivate: [AuthGuard], loadChildren: './pages/info-docente/info-docente.module#InfoDocentePageModule' },
  { path: 'info-estudiante', canActivate: [AuthGuard], loadChildren: './pages/info-estudiante/info-estudiante.module#InfoEstudiantePageModule' },
  { path: 'info-nodocente', canActivate: [AuthGuard], loadChildren: './pages/info-no-docente/info-no-docente.module#InfoNoDocentePageModule' },
  { path: 'info-secundaria', canActivate: [AuthGuard], loadChildren: './pages/info-estudiante-secundaria/info-estudiante-secundaria.module#InfoEstudianteSecundariaPageModule' },
  { path: 'inicio', canActivate: [AuthGuard], loadChildren: './pages/inicio/inicio.module#InicioPageModule' },
  { path: 'mensajes', canActivate: [AuthGuard], loadChildren: './pages/mensajes/mensajes.module#MensajesPageModule' },
  { path: 'mensajes/:idMensaje', canActivate: [AuthGuard], loadChildren: './pages/mensaje/mensaje.module#MensajePageModule' },
  { path: 'mensajes/guarani/:idMensaje', loadChildren: './pages/mensaje-guarani/mensaje-guarani.module#MensajeGuaraniPageModule' },
  { path: 'moodle', canActivate: [AuthGuard], loadChildren: './pages/moodle/moodle.module#MoodlePageModule' },
  { path: 'pp-propuestas', canActivate: [AuthGuard], loadChildren: './pages/pp-propuestas/pp-propuestas.module#PpPropuestasPageModule' },
  { path: 'pp-voto', canActivate: [AuthGuard], loadChildren: './pages/pp-voto/pp-voto.module#PpVotoPageModule' },
  { path: 'crrhh-voto', canActivate: [AuthGuard], loadChildren: './pages/crrhh-voto/crrhh-voto.module#CRRHHVotoPageModule' },
  { path: 'preguntas-frecuentes', loadChildren: './pages/preguntas-frecuentes/preguntas-frecuentes.module#PreguntasFrecuentesPageModule' },
  { path: 'rad-formulario', canActivate: [AuthGuard], loadChildren: './pages/rad-formulario/rad-formulario.module#RadFormularioPageModule' },
  { path: 'rad-listado', canActivate: [AuthGuard], loadChildren: './pages/rad-listado/rad-listado.module#RadListadoPageModule' },
  { path: 'rad-qr/:idRegistro', canActivate: [AuthGuard], loadChildren: './pages/rad-qr/rad-qr.module#RadQrPageModule' },
  { path: 'recibos-haberes', canActivate: [AuthGuard], loadChildren: './pages/recibos-haberes/recibos-haberes.module#RecibosHaberesPageModule' },
  { path: 'recupero-clave', loadChildren: './pages/recupero-clave/recupero-clave.module#RecuperoClavePageModule' },
  { path: 'registro', loadChildren: './pages/registro/registro.module#RegistroPageModule' },
  { path: 'registro-asistencia-ungs', canActivate: [AuthGuard], loadChildren: './pages/solicitud-ingreso-campus/solicitud-ingreso-campus.module#SolicitudIngresoCampusPageModule' },
  { path: 'registro-asistencia-ungs-vista/:id', canActivate: [AuthGuard], loadChildren: './pages/solicitud-ingreso-campus-vista/solicitud-ingreso-campus-vista.module#SolicitudIngresoCampusVistaPageModule' },
  { path: 'registros-asistencia-ungs', canActivate: [AuthGuard], loadChildren: './pages/solicitudes-ingreso-campus/solicitudes-ingreso-campus.module#SolicitudesIngresoCampusPageModule' },
  { path: 'registro-vacunacion', canActivate: [AuthGuard], loadChildren: './pages/registro-vacunacion/registro-vacunacion.module#RegistroVacunacionPageModule' },
  { path: 'registros-vacunacion', canActivate: [AuthGuard], loadChildren: './pages/registros-vacunacion/registros-vacunacion.module#RegistrosVacunacionPageModule' },
  { path: 'salir', loadChildren: './pages/salir/salir.module#SalirPageModule' },
  { path: 'servicios', canActivate: [AuthGuard], loadChildren: './pages/servicios/servicios.module#ServiciosPageModule' },
  { path: 'solicitud-reintegro-gastos', canActivate: [AuthGuard], loadChildren: './pages/solicitud-reintegro-gastos/solicitud-reintegro-gastos.module#SolicitudReintegroGastosPageModule' },
  { path: 'solicitud-reintegro-gastos-vista/:id', canActivate: [AuthGuard], loadChildren: './pages/solicitud-reintegro-gastos-vista/solicitud-reintegro-gastos-vista.module#SolicitudReintegroGastosVistaPageModule' },
  { path: 'solicitudes-reintegro-gastos', canActivate: [AuthGuard], loadChildren: './pages/solicitudes-reintegro-gastos/solicitudes-reintegro-gastos.module#SolicitudesReintegroGastosPageModule' },
  { path: 'solicitud-trabajo-bimodal', canActivate: [AuthGuard], loadChildren: './pages/solicitud-trabajo-bimodal/solicitud-trabajo-bimodal.module#SolicitudTrabajoBimodalPageModule' },
  { path: 'validar-cupon', canActivate: [AuthGuard], loadChildren: './pages/validar-cupon/validar-cupon.module#ValidarCuponPageModule' },
  { path: '', redirectTo: 'inicio', pathMatch: 'full' },
  { path: '**', loadChildren: './pages/not-found/not-found.module#NotFoundPageModule' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
