import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { GoogleAnalytics } from '@ionic-native/google-analytics/ngx';

@Injectable({
  providedIn: 'root'
})
export class HerramientasService {

  constructor(
    private platform: Platform,
    public ga: GoogleAnalytics
  ) { }

  // esApp(): boolean {
  //   console.log('Activa: ' + document.URL);
  //   return !document.URL.startsWith('http') && !document.URL.startsWith('https');
  // }

  // esBrowser(): boolean {
  //   return !this.esApp();
  // }

  // esMobileBrowser(): boolean {
  //   return this.esBrowser() && (this.platform.is('mobile') || this.platform.is('mobileweb'));
  // }

  esApp(): boolean {
    return this.platform.is('mobile') && (this.platform.is('android') || this.platform.is('ios'));
  }

  esBrowser(): boolean {
    return !this.esApp();
  }

  esMobileBrowser(): boolean {
    return this.esBrowser() && (this.platform.is('mobile') || this.platform.is('mobileweb'));
  }

  storage(): Storage {
    return this.esApp() ? localStorage : sessionStorage;
  }

  limpiarEspacios(valor: string): string {
    if (valor) {
      return valor.replace(new RegExp(/\s+/, 'g'), '');
    } else {
      return null;
    }
  }

  allSettled(promises: Promise<any>[]) {
    const wrappedPromises = promises.map(p => Promise.resolve(p)
      .then(
        val => ({ state: 'fulfilled', value: val }),
        err => ({ state: 'rejected', reason: err })));
    return Promise.all(wrappedPromises);
  }
}
