export const ConfigService = {
  host: 'testing.ungs.edu.ar',
  port: '443',
  API_URL: 'https://testing.ungs.edu.ar:8080',
  HOME_URL: 'https://testing.ungs.edu.ar',
  MPD_VIEJO_URL: 'https://testing.ungs.edu.ar:3010',
  MPD_URL: 'https://sudocu.ungs.edu.ar/mpd/',
  DDJJ_URL: 'https://testing.ungs.edu.ar:4020',
  ENCUESTAS_DOCENTES_URL: 'https://testing.ungs.edu.ar:3002/#!/activa/encuestas',
};
