import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from '../config/ungs_activa_mobile_config';

@Injectable({
  providedIn: 'root'
})
export class MoodleService {

  config = ConfigService;

  constructor(
    private http: HttpClient
  ) { }

  login(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.post(this.config.API_URL + '/moodle/login', null)
        .toPromise()
        .then((response: Response) => resolve('Moodle logueado'))
        .catch((error: Response) => reject('Moodle No logueado'));
    });
  }

  traerCursosPorUsuario(dni: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get(this.config.API_URL + '/moodle/cursos/' + dni)
        .toPromise()
        .then((response: Response) => resolve(response))
        .catch((error: Response) => reject(error));
    });
  }
}
