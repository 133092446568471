import { Injectable } from '@angular/core';
import { ConfigService } from '../config/ungs_activa_mobile_config';
import { HerramientasService } from '../herramientas/herramientas.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class RadService {

  config = ConfigService;

  constructor(
    private http: HttpClient,
    public herramientas: HerramientasService
  ) { }

  traerRad(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get(this.config.API_URL + '/ungs_activa/rad')
        .toPromise()
        .then((response: any) => resolve(response))
        .catch((error: any) => reject(error));
    });
  }

  informar(materiaInformada) {
    return new Promise((resolve, reject) => {
      this.http.post(this.config.API_URL + '/ungs_activa/rad', materiaInformada)
        .toPromise()
        .then((response: any) => resolve(response))
        .catch((error: any) => reject(error));
    });
  }

  traerQR(idRegistro) {
    return new Promise((resolve, reject) => {
      this.http.get(this.config.API_URL + '/ungs_activa/rad/qr/' + idRegistro)
        .toPromise()
        .then((response: any) => resolve(response))
        .catch((error: any) => reject(error));
    });
  }
}
