import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from '../config/ungs_activa_mobile_config';

@Injectable({
  providedIn: 'root',
})
export class BecasMaterialesService {
  config = ConfigService;
  constructor(private http: HttpClient) {}

  nuevoPeriodo(periodo: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .post(this.config.API_URL + '/ungs_activa/servicio_becas_materiales/nuevo_periodo', periodo)
        .toPromise()
        .then((response: any) => resolve(response))
        .catch((error: any) => reject(error));
    });
  }

  modificarEstadoPeriodo(periodo: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .post(this.config.API_URL + '/ungs_activa/servicio_becas_materiales/modificar_estado_periodo', periodo)
        .toPromise()
        .then((response: any) => resolve(response))
        .catch((error: any) => reject(error));
    });
  }

  obtenerPeriodos(fecha?: boolean): Promise<any> {
    let url = this.config.API_URL + '/ungs_activa/servicio_becas_materiales/obtener_periodos';
    if (fecha) {
      url += '?fecha=' + fecha;
    }
    return new Promise((resolve, reject) => {
      this.http
        .get(url)
        .toPromise()
        .then((response: any) => resolve(response))
        .catch((error: any) => reject(error));
    });
  }

  nuevaSolicitud(solicitud: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .post(this.config.API_URL + '/ungs_activa/servicio_becas_materiales/nueva_solicitud', solicitud)
        .toPromise()
        .then((response: any) => resolve(response))
        .catch((error: any) => reject(error));
    });
  }

  obtenerSolicitud(solicitud): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .get(this.config.API_URL + '/ungs_activa/servicio_becas_materiales/obtener_solicitud' + '?dni=' + solicitud.dni + '&idPeriodo=' + solicitud.idPeriodo)
        .toPromise()
        .then((response: any) => resolve(response))
        .catch((error: any) => reject(error));
    });
  }

  obtenerSolicitudDNI(solicitud): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .get(this.config.API_URL + '/ungs_activa/servicio_becas_materiales/obtener_solicitud_dni' + '?dni=' + solicitud.dni + '&idPeriodo=' + solicitud.idPeriodo)
        .toPromise()
        .then((response: any) => resolve(response))
        .catch((error: any) => reject(error));
    });
  }

  obtenerSolicitudQR(solicitud): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .get(this.config.API_URL + '/ungs_activa/servicio_becas_materiales/obtener_solicitud_qr' + '?dni=' + solicitud.dni + '&idPeriodo=' + solicitud.idPeriodo)
        .toPromise()
        .then((response: any) => resolve(response))
        .catch((error: any) => reject(error));
    });
  }

  realizoSolicitud(solicitud): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .get(this.config.API_URL + '/ungs_activa/servicio_becas_materiales/realizo_solicitud' + '?dni=' + solicitud.dni + '&idPeriodo=' + solicitud.idPeriodo)
        .toPromise()
        .then((response: any) => resolve(response))
        .catch((error: any) => reject(error));
    });
  }

  modificarSolicitudEntregado(solicitud: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .put(this.config.API_URL + '/ungs_activa/servicio_becas_materiales/modificar_solicitud_entregado', solicitud)
        .toPromise()
        .then((response: any) => resolve(response))
        .catch((error: any) => reject(error));
    });
  }

  obtenerLogs(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .get(this.config.API_URL + '/ungs_activa/servicio_becas_materiales/obtener_logs')
        .toPromise()
        .then((response: any) => resolve(response))
        .catch((error: any) => reject(error));
    });
  }
}
