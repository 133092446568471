import { Injectable } from '@angular/core';
import { HerramientasService } from '../herramientas/herramientas.service';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HttpInterceptorService implements HttpInterceptor {

  constructor(
    public herramientas: HerramientasService
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    req = req.clone({
      setHeaders: {
        // 'timeout': '5000',
        // responseType: 'text' as 'json',
        // 'Content-Type': 'application/json',
        fromApp: this.herramientas.esApp().toString()
      }
    });

    if (this.herramientas.storage().getItem('UNGSxt_session')) {
      req = req.clone({
        setHeaders: {
          Authorization: this.herramientas.storage().getItem('UNGSxt_session'),
        }
      });
    }

    return next.handle(req);
  }
}
