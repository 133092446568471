import { Injectable } from '@angular/core';
import { ConfigService } from '../config/ungs_activa_mobile_config';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class BecasEstudiantesService {
  config = ConfigService;
  constructor(private http: HttpClient) {}

  nuevoPeriodo(periodo: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .post(this.config.API_URL + '/ungs_activa/servicio_becas_estudiantes/nuevo_periodo', periodo)
        .toPromise()
        .then((response: any) => resolve(response))
        .catch((error: any) => reject(error));
    });
  }

  modificarEstadoPeriodo(periodo: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .post(this.config.API_URL + '/ungs_activa/servicio_becas_estudiantes/modificar_estado_periodo', periodo)
        .toPromise()
        .then((response: any) => resolve(response))
        .catch((error: any) => reject(error));
    });
  }

  obtenerPeriodos(fecha?: boolean): Promise<any> {
    let url = this.config.API_URL + '/ungs_activa/servicio_becas_estudiantes/obtener_periodos';
    if (fecha) {
      url += '?fecha=' + fecha;
    }
    return new Promise((resolve, reject) => {
      this.http
        .get(url)
        .toPromise()
        .then((response: any) => resolve(response))
        .catch((error: any) => reject(error));
    });
  }

  nuevaSolicitud(solicitud: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .post(this.config.API_URL + '/ungs_activa/servicio_becas_estudiantes/nueva_solicitud', solicitud)
        .toPromise()
        .then((response: any) => resolve(response))
        .catch((error: any) => reject(error));
    });
  }

  cargarDatosBancarios(solicitud: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .post(this.config.API_URL + '/ungs_activa/servicio_becas_estudiantes/datos_bancarios', solicitud)
        .toPromise()
        .then((response: any) => resolve(response))
        .catch((error: any) => reject(error));
    });
  }

  obtenerSolicitud(solicitud: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .get(this.config.API_URL + '/ungs_activa/servicio_becas_estudiantes/obtener_solicitudes' + '?dni=' + solicitud.dni + '&id_periodo=' + solicitud.idPeriodo)
        .toPromise()
        .then((response: any) => resolve(response))
        .catch((error: any) => reject(error));
    });
  }

  realizoSolicitud(solicitud: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .get(this.config.API_URL + '/ungs_activa/servicio_becas_estudiantes/realizo_solicitud' + '?dni=' + solicitud.dni + '&idPeriodo=' + solicitud.idPeriodo)
        .toPromise()
        .then((response: any) => resolve(response))
        .catch((error: any) => reject(error));
    });
  }

  modificarSolicitudAprobado(solicitud: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .post(this.config.API_URL + '/ungs_activa/servicio_becas_estudiantes/modificar_solicitud_aprobado', solicitud)
        .toPromise()
        .then((response: any) => resolve(response))
        .catch((error: any) => reject(error));
    });
  }

  modificarSolicitudesMostrarResultado(solicitud: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .post(this.config.API_URL + '/ungs_activa/servicio_becas_estudiantes/modificar_solicitudes_mostrar_resultado', solicitud)
        .toPromise()
        .then((response: any) => resolve(response))
        .catch((error: any) => reject(error));
    });
  }

  modificarSolicitudEntregado(solicitud: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .post(this.config.API_URL + '/ungs_activa/servicio_becas_estudiantes/modificar_solicitud_entregado', solicitud)
        .toPromise()
        .then((response: any) => resolve(response))
        .catch((error: any) => reject(error));
    });
  }

  obtenerLogs(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .get(this.config.API_URL + '/ungs_activa/servicio_becas_estudiantes/obtener_logs')
        .toPromise()
        .then((response: any) => resolve(response))
        .catch((error: any) => reject(error));
    });
  }
}
