import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from '../config/ungs_activa_mobile_config';

@Injectable({
  providedIn: 'root'
})
export class CuponesService {

  config = ConfigService;
  constructor(
    private http: HttpClient
  ) { }

  traerCuponeras(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get(this.config.API_URL + '/ungs_activa/servicio_cupones/cuponeras')
        .toPromise()
        .then(response => resolve(response))
        .catch(error => reject(error));
    });
  }

  traerCupones(idCuponera: number): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get(this.config.API_URL + '/ungs_activa/servicio_cupones/cuponeras/' + idCuponera + '/cupones')
        .toPromise()
        .then(response => resolve(response))
        .catch(error => reject(error));
    });
  }

  traerCupon(nroCupon: number): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get(this.config.API_URL + '/ungs_activa/cupon/' + nroCupon)
        .toPromise()
        .then(response => resolve(response))
        .catch(error => reject(error));
    });
  }

  traerQRCupon(nroCupon: number): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.get(this.config.API_URL + '/ungs_activa/cupones/qr/' + nroCupon, { responseType: 'text' })
        .toPromise()
        .then(response => resolve(response))
        .catch(error => reject(error));
    });
  }

  validarCupon(codigo: object): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.post(this.config.API_URL + '/ungs_activa/cupones/qr/validar', codigo)
        .toPromise()
        .then(response => resolve(response))
        .catch(error => reject(error));
    });
  }

  aceptarCupon(cupon: object): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.put(this.config.API_URL + '/ungs_activa/cupones/qr/canjear', cupon)
        .toPromise()
        .then(response => resolve(response))
        .catch(error => reject(error));
    });
  }

  asignarCupon(cupon: object) {
    return new Promise((resolve, reject) => {
      this.http.post(this.config.API_URL + '/ungs_activa/cupones', cupon)
        .toPromise()
        .then(response => resolve(response))
        .catch(error => reject(error));
    });
  }
}
